import { h, Component } from 'preact';
import { Router } from 'preact-router';
import { BASE_URL, SITE_URL } from '../config';

import Header from './header';
import Footer from './footer';
import YoutubePopup from './youtubepopup';
import ChatPopup from './chatpopup';
import CookiePopup from './cookiepopup';

// Code-splitting is automated for routes
import Home from '../routes/home';
import Contacts from '../routes/contacts';
import About from '../routes/about';
import Cookies from '../routes/cookies';
import Products from '../routes/products';
import Projects from '../routes/projects';
import Stories from '../routes/stories';

export default class App extends Component {

	state = { user: null, cookiesClosed: false, isStudio: false, currentUrlClass: '' };
  checkLoggedInPromise = null;

  checkLoggedIn = () => {
  	if (this.checkLoggedInPromise === null) {
  		this.checkLoggedInPromise = new Promise((resolve, reject) => {
  			if (typeof window !== 'undefined') {

  				this.appWrpEl = document.getElementById('appWrp');
  				let xmlhttp = new XMLHttpRequest();

  				let t = this;

  				xmlhttp.withCredentials = true;
  				xmlhttp.onreadystatechange = function() {
  					if (this.readyState === 4 && this.status === 200) {
  						let res = JSON.parse(this.responseText);
  						if (res.s) {
  							t.setState({ user: res.u });
  							resolve(true);
  							// loadAnal(res.ai);
  						}
  						else {
  							resolve(false);
  						}
  					}
  					else {
  						// resolve(false);
  					}
  				};

  				xmlhttp.open('GET', SITE_URL + 'auth/get-current-user', true);
  				xmlhttp.send();
  			}
  			else {
  				reject();
  			}
  		});
  	}
  	return this.checkLoggedInPromise;
  }

  cookieAcceptedCB() {
  	// this.checkLoggedIn();
  	// loadAnal();
  	// campaignLogger();
  }

  constructor() {
  	super();

  	this.appWrpEl = false;

  	this.headerCBFun = this.headerCB.bind(this);
  	// this.handleRouteFun = this.handleRoute.bind(this);

  }

  componentDidMount() {
  	this.checkIsStudio();
  	this.appWrpEl = document.getElementById('appWrp');
  	if (typeof window !== 'undefined') {
  		if (localStorage.getItem('cookieaccept')) {
  			this.setState({ cookiesClosed: true });
  			this.cookieAcceptedCB();
  		}
  	}
  }

  checkIsStudio() {

  	/*if (typeof window !== 'undefined') {
			this.setState({isStudio: window.location.href.indexOf('chatbot-studio') !== -1})
		}*/
  }

	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRouteFun = e => {
		this.setState({ currentUrlClass: e.url.replace('/', '') });
		if (typeof window !== 'undefined') {
			setTimeout(() => {
				if (e.url === '/digitaltwin' || e.url === '/' || e.url === '/contacts') {
					let querlyEl = document.getElementById('querloEmbd');
					querlyEl.style.display = 'none';
				}
				else {
					let querlyEl = document.getElementById('querloEmbd');
					querlyEl.style.display = 'block';
				}
			}, 301); // wait for querly to render and then hide it

		}

		this.checkIsStudio();

		// console.log('sending pageview event');
		/*if (typeof window !== 'undefined') window.scrollTo(0,0);
		if (process.env.NODE_ENV === 'production') {
			if (typeof gtag !== 'undefined') {
				// console.log('sending gtag', window.location.origin + e.url)
				gtag('config', 'UA-6924977-74', {
					page_location: window.location.origin + e.url
				});
			}
		}*/

		if (this.appWrpEl) this.appWrpEl.classList.remove('openMenu');
	};

	headerCB = e => {
		e.preventDefault();
		if (this.appWrpEl) {
			if (this.appWrpEl.classList.contains('openMenu'))
				this.appWrpEl.classList.remove('openMenu');
			else
				this.appWrpEl.classList.add('openMenu');
		}
	};

	closePopup() {
		this.setState({ ytData: null });
	}

	acceptCookies (accept) {
		if (typeof window !== 'undefined') {
			if (accept) {
				localStorage.setItem('cookieaccept', true);
				this.cookieAcceptedCB();
			}
		}
		this.setState({ cookiesClosed: true });
	}

	closeChat() {
		console.log('closing chat');
		this.setState({ chatData: null });
	}

	handleYTRequest (id) {
		// console.log(id)
		this.setState({ ytData: i });
	}

	handleChatRequest (id) {
		// console.log(id)
		this.setState({ chatData: id });
	}

	render(props, state) {

		return (<div id="appWrp" className={state.currentUrlClass}>
			<Header cb={this.headerCBFun} u={state.user} isStudio={state.isStudio} />
			<main>
				<Router onChange={this.handleRouteFun}>
					<Home path={BASE_URL} ytCB={this.handleYTRequest.bind(this)} default />
					<About path={BASE_URL + 'about/'} />
					<Contacts path={BASE_URL + 'contacts'} />
					<Products path={BASE_URL + 'products'} />
					<Projects path={BASE_URL + 'projects'} />
					<Stories path={BASE_URL + 'success-stories'} />
					<Cookies path={BASE_URL + 'cookies'} />
				</Router>
			</main>
			<Footer />
			{ state.ytData ? <YoutubePopup data={state.ytData} cb={this.closePopup.bind(this)} /> : '' }
			{ state.cookiesClosed ? '' : <CookiePopup cb={this.acceptCookies.bind(this)} /> }
			{ state.chatData ? <ChatPopup data={state.chatData}  cb={this.closeChat.bind(this)} /> : '' }

		</div>);
	}
}
