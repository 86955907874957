import {Component, h} from 'preact';
import { Link } from 'preact-router/match';
import Illustration from "../illustration";
import Mobilemenutoggle from "../menumobiletoggle";
import style from './style.scss';
import { STATIC_BASE_URL, BASE_URL, SITE_URL } from "../../config";

class Header extends Component {

	state = { showFirstMenu: false, showSecondMenu: false };

	toggleFirstMenu = () => {
		this.setState({ showFirstMenu: !this.state.showFirstMenu, showSecondMenu: false, showThirdMenu: false });
	};

	render(props, state) {

		const mainMenu = (<>
			<ul class="mainMenu" style={!props.isStudio ? { display: 'flex' } : { display: 'none' }}>
				<li><Link activeClassName={style.sel} href={BASE_URL + 'about'}>About</Link></li>
				<li><Link activeClassName={style.sel} href={BASE_URL + 'projects'}>Projects</Link></li>
				<li><Link activeClassName={style.sel} href={BASE_URL + 'products'}>Products</Link></li>
				<li><Link activeClassName={style.sel} href={BASE_URL + 'success-stories'}>Success Stories</Link></li>
				<li><Link activeClassName={style.sel} href={BASE_URL + 'contacts'}>Contacts</Link></li>
			</ul>
		</>);

		return (<header class={style.headMenu}>
			<div class={style.mainWrp}>
				<div class={`logo ${style.logo}`}>
					<a href={BASE_URL}>
						<Illustration src={STATIC_BASE_URL + 'logo-quaone'} alt="Quaone" />
					</a>
				</div>
				<Mobilemenutoggle cb={props.cb} />
				<nav id="mainMenu"
					class={ style.navStyle + ' main-nav menuItemCheck' }
					role="navigation"
				>

					{ mainMenu }

				</nav>
			</div>
		</header>);
	}

}

export default Header;
