import { h } from 'preact';
import style from './style.scss';
import { BASE_URL } from '../../config';
import { Link } from 'preact-router/match';

const Footer = () => (
	<footer class={style.foot}>
		<div className={style.mainWrp}>
			<div>
				<nav>
					<ul>
						<li>
							<Link href={BASE_URL + 'cookies'}>Cookies Policy</Link>
						</li>
						<li>
							<Link href={BASE_URL + 'contacts'}>Contacts</Link>
						</li>
					</ul>
				</nav>
				<p>© QuaOne 2024 all rights reserved</p>
			</div>

			<ul class={ style.socials }>
				<li>
					<a href="https://www.linkedin.com/company/quaone/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin" /></a>
				</li>
			</ul>
		</div>
	</footer>
);

export default Footer;
